import React from 'react';


function Frontend (){
    return(
        <div className='skills_content'>
            <h3 className='skills_title'>Web development</h3>

            <div className='skills_box'>
                <div className='skills_group'>
                    <div className='skills_data'>
                        <i class='bx bx-badge-check'></i>

                        <div>
                            <h3 className='skills_name'>HTML</h3>
                            {/* <span className='skills_level'>basic</span> */}
                        </div>
                    </div>
                    
                    <div className='skills_data'>
                        <i class='bx bx-badge-check'></i>

                        <div>
                            <h3 className='skills_name'>CSS</h3>
                            {/* <span className='skills_level'>Intermediate</span> */}
                        </div>
                    </div>

                    <div className='skills_data'>
                        <i class='bx bx-badge-check'></i>

                        <div>
                            <h3 className='skills_name'>Bootstrap</h3>
                            {/* <span className='skills_level'>Intermediate</span> */}
                        </div>
                    </div>
                
                    <div className='skills_data'>
                        <i class='bx bx-badge-check'></i>

                        <div>
                            <h3 className='skills_name'>React</h3>
                            {/* <span className='skills_level'>Advanced</span> */}
                        </div>
                    </div>

                    <div className='skills_data'>
                        <i class='bx bx-badge-check'></i>

                        <div>
                            <h3 className='skills_name'>Javascript</h3>
                            {/* <span className='skills_level'>Advanced</span> */}
                        </div>
                    </div>
                </div>

                <div className='skills_group'>
                    
                    <div className='skills_data'>
                        <i class='bx bx-badge-check'></i>

                        <div>
                            <h3 className='skills_name'>Node js</h3>
                            {/* <span className='skills_level'>Advanced</span> */}
                        </div>
                    </div>
                
                    <div className='skills_data'>
                        <i class='bx bx-badge-check'></i>

                        <div>
                            <h3 className='skills_name'>Express</h3>
                            {/* <span className='skills_level'>Advanced</span> */}
                        </div>
                    </div>
                
                    <div className='skills_data'>
                        <i class='bx bx-badge-check'></i>

                        <div>
                            <h3 className='skills_name'>EJS</h3>
                            {/* <span className='skills_level'>Advanced</span> */}
                        </div>
                    </div>
                    
                    <div className='skills_data'>
                        <i class='bx bx-badge-check'></i>

                        <div>
                            <h3 className='skills_name'>SQL</h3>
                            {/* <span className='skills_level'>Advanced</span> */}
                        </div>
                    </div>

                    <div className='skills_data'>
                        <i class='bx bx-badge-check'></i>

                        <div>
                            <h3 className='skills_name'>MongoDB</h3>
                            {/* <span className='skills_level'>Advanced</span> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Frontend;